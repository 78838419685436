/* BottomNav.css */
.bottom-nav {
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-around;
    background-color: #000000;
    box-shadow: 0 -2px 5px rgba(0,0,0,0.1);
    padding: 10px 0;
  }
  
  .nav-item {
    flex: 1;
    text-align: center;
    color: #ffffff;
    text-decoration: none;
    font-size: 16px;
    /* font-family: 'averta', 'Helvetica Neue', sans-serif; */
    font-weight: 400;
  }
  
  .nav-item:hover {
    color: #ACFF99; /* Change font color on hover */
    /* background-color: #ffffff; */
  }
  
  @media (max-width: 667px) {
    .nav-item {
      font-size: 14px;
    }
  }
  