:root {
  --background-color: #212529;
  --text-color: #ffffff;
  --hover-color: #ACFF99;
  --padding: 10px 20px;
  --transition-duration: 0.3s;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--background-color);
  padding: var(--padding);
  color: var(--text-color);
  position: relative;
}

.logo {
  display: flex;
  align-items: center;
}

.logo-image {
  width: auto;
  height: 45px; /* Adjust the height as needed */
}

.menu-toggle {
  display: none;
  font-size: 24px;
  background: none;
  border: none;
  color: var(--text-color);
  cursor: pointer;
}

.navigation {
  display: flex;
}

.navigation ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.navigation li {
  margin: 0 10px;
}

.navigation a {
  color: var(--text-color);
  text-decoration: none;
  transition: color var(--transition-duration) ease;
}

.navigation a:hover,
.navigation a:focus {
  color: var(--hover-color);
  outline: none;
}

.user-options {
  display: flex;
  align-items: center;
  position: relative; /* Add this line if dropdown is positioned incorrectly */
}

.user-icon {
  margin-left: 20px;
  cursor: pointer;
  transition: transform var(--transition-duration) ease;
  position: relative; /* Ensure it is positioned relatively */
  display: flex;
  align-items: center;
  background-color: transparent;
  color: var(--text-color);
}

.user-icon:hover {
  transform: scale(1.2);
}

/* Media Query for Mobile Devices */
@media (max-width: 667px) {
  .header {
      /* display: flex; */
      flex-direction: column;
      align-items: flex-start;
  }

  .menu-toggle {
      display: block;
      position: absolute;
      top: 10px;
      right: 20px;
  }

  .navigation {
      display: none;
      flex-direction: column;
      width: 100%;
  }

  .navigation.open {
      display: flex;
  }

  .navigation ul {
      flex-direction: column;
      width: 100%;
  }

  .navigation li {
      margin: 10px 0;
  }

  .user-options {
    margin-top: 10px;
    width: 100%;
    justify-content: space-between;
    position: static; /* Adjust this line if necessary for mobile view */
  }


}
