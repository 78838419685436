.results-table-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.flight-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0.1, 0, 0, 0.1);
  margin: 0;
  padding: 20px;
  width: 150%;
  min-width: 50%; /* Ensure it covers at least 50% of the page width */
  /* max-width: 80%; Ensure it doesn't exceed 80% of the parent width */
  display: flex;
  flex-direction: column;
  border: 1px solid #f3f3f3; /* Add this line for the border */
  margin-bottom: 10px;
}

.flight-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.flight-card-header .flight-number {
  font-size: 18px;
  font-weight: bold;
}

.flight-card-header .flight-status {
  font-size: 16px;
  font-weight: bold;
  color: gray;
}

.flight-card-body {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flight-card-body .time-scheduled {
  font-size: 32px;
  font-weight: bold;
}

.flight-card-body .time-arrived-departed {
  font-size: 32px;
  font-weight: bold;
}

.flight-card-body .time-delayed {
  font-size: 32px;
  font-weight: bold;
}

.flight-card-body .time-canceled {
  font-size: 32px;
  font-weight: bold;
  text-decoration: line-through;
  /* color: rgb(204, 0, 0); */
}

.flight-card-body .date-variation-arrival {
  font-size: 14px;
  color: rgb(255, 255, 255);
  text-align: center;
  margin-bottom: 10px;
  font-weight: normal;
}

.flight-card-body .date-variation-departure {
  font-size: 14px;
  color: rgb(204, 0, 0);
  text-align: center;
  /* width: 100%; Ensures the element spans the full width */
  margin-bottom: 10px;
  font-weight: normal;
}

.flight-card-body .details {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1px; /* Add margin for spacing */
}

/* .flight-card-body .details span {
  font-size: 14px;
  color: rgb(49, 49, 49);
  align-self: auto;
} */

.flight-card-body .details .scheduled-departure-changed {
  font-size: 14px;
  color: rgb(49, 49, 49);
  align-self: flex-start;
  text-decoration: line-through;
}

.flight-card-body .details .scheduled-departure-unchanged {
  font-size: 14px;
  color: rgb(49, 49, 49);
  align-self: flex-start;
}

.flight-card-body .details .scheduled-arrival-changed {
  font-size: 14px;
  color: rgb(49, 49, 49);
  align-self: flex-end;
  text-decoration: line-through;
}

.flight-card-body .details .scheduled-arrival-unchanged {
  font-size: 14px;
  color: rgb(49, 49, 49);
  align-self: flex-end;
}

.flight-card-body .details .city {
  font-size: 14px;
  color: rgb(49, 49, 49);
  align-self: auto;
}


.flight-card-body .icon {
  font-size: 24px;
  color: gray;
  margin: 10px 0 0px; /* Add margin for spacing */
}

.airplane {
  height: 40px; /* Adjust the height as needed */
}


/* New styles for status colors */
.flight-status-scheduled {
  color: green;
  font-weight: bold;
}

.flight-status-arrived-departed {
  color: gray;
  font-weight: bold;
}

.flight-status-delayed {
  color: orange;
  font-weight: bold;
}

.flight-status-canceled {
  color: rgb(204, 0, 0);
  font-weight: bold;
}

.no-flights-message {
  font-size: 1em;
  color: #d9534f;
  text-align: center;
  margin-top: 0px;
  /* margin: 20px 0; */
  padding: 20px;
  border: 2px solid #df7672;
  border-radius: 8px;
  background-color: #ffe8e8;
  position: relative;
}

.search-icon {
  font-size: 1em;
  /* margin-bottom: 10px; */
  color: #d9534f;
}

.no-flights-message ul {
  text-align: left;
  display: inline-block;
  margin-top: 10px;
  padding-left: 20px;
}

.no-flights-message ul li {
  font-size: 0.9em;
  margin-bottom: 0px;
}

/* Responsive styles for mobile */
@media (max-width: 667px) {
  .results-table-wrapper {
    flex-direction: column;
    align-items: center;
  }

  .flight-card {
    width: 105%;
    margin: 5px 0;
  }
}
