.search-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.search-heading {
  margin-top: 30px; 
  font-size: 2.5em; /* Larger font size for heading */
  margin-bottom: 30px; /* More space below heading */
}

.search-form {
  display: flex;
  gap: 15px; /* Increased gap between input groups */
  margin-bottom: 30px;
  flex-wrap: wrap; /* Allow wrapping of input groups */
}

.input-group {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 30px;
  overflow: hidden;
  font-size: 1.2em; /* Larger font size */
  font-weight: 400; /* Regular weight text */
}

.input-group-addon {
  background-color: #f4f4f4;
  padding: 15px; /* Increased padding for larger appearance */
  border-right: 1px solid #ccc;
  color: #333;
  font-weight: bold; /* Make the text bold */
  width: 30px; /* Fixed width for all addons */
}

.input-group-icon {
  background-color: #f4f4f4;
  color: #000000;
  font-size: 1em; /* Adjust icon size */
}

.search-input {
  padding: 15px; /* Increased padding for larger appearance */
  border: none;
  outline: none;
  font-size: 0.9em; /* Larger font size */
  font-weight: 400; /* Regular weight text */
  font-family: 'Averta', sans-serif;
  color: gray; /* Add this line to change the text color to gray */
}

.search-button {
  background-color: #8C0AC8;
  color: white;
  padding-top: 12px;
  padding-right: 12px;
  padding-bottom: 18px;
  padding-left: 12px;
  border: 2px solid transparent; /* No border by default */
  border-radius: 30px;
  cursor: pointer;
  font-size: 1.6em; /* Larger font size */
  font-weight: 600; /* Make the text bold */
  width: 350px; /* Set a fixed width for the button */
  text-align: center; /* Center the text inside the button */
  transition: all 0.3s ease; /* Smooth transition */
  font-family: 'Averta', sans-serif;
}

.search-button:hover {
  background-color: white; /* Change background color on hover */
  color: #8C0AC8; /* Change text color on hover */
  border: 2px solid #8C0AC8; /* Add border on hover */
}

.loading-screen {
  font-size: 1.2em; /* Larger font size */
  color: #666;
  font-weight: 400; /* Regular weight text */
}

.error-message {
  color: red;
  font-size: 1.2em; /* Larger font size */
  font-weight: 400; /* Regular weight text */
}

.results-container {
  margin-top: 30px;
  font-size: 1.2em; /* Larger font size */
  font-weight: 400; /* Regular weight text */
}

.page-loader {
  height: 200px; /* Adjust the height as needed */
}

/* Date picker style */
.react-datepicker {
  font-family: 'Averta', sans-serif;
}

/* Specific styling for the calendar */
.react-datepicker__header {
  font-family: 'Averta', sans-serif;
}

.react-datepicker__current-month,
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  font-family: 'Averta', sans-serif;
}

.flight-prefix {
  padding-left: 10px; /* Match the padding with input */
  font-size: 0.9em; /* Match font size with input */
  color: gray; /* Match the text color with input */
}

/* Media Queries for Mobile Responsiveness */
@media (max-width: 667px) {
  .search-heading {
    font-size: 2em; /* Smaller font size for heading */
    margin-top: 0px; 
  }

  .search-form {
    flex-direction: column;
    gap: 10px; /* Reduce gap between input groups */
  }

  .input-group {
    font-size: 1em; /* Smaller font size */
  }

  .input-group-addon {
    padding: 10px; /* Smaller padding */
  }

  .search-input {
    padding: 10px; /* Smaller padding */
  }

  .search-button {
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 14px;
    padding-left: 10px;
    font-size: 1.2em; /* Smaller font size */
    width: 50%; /* Full width button */
    
  }

  .loading-screen, .error-message, .results-container {
    font-size: 1em; /* Smaller font size */
    padding-bottom: 40px;
  }

  .page-loader {
    height: 150px; /* Adjust the height as needed */
  }
}
