.dropbtn {
  background-color: #212529;
  color: white;
  font-size: 16px;
  border: none;
  align-items: center;
  display: flex;
  transition: transform var(--transition-duration) ease;
  padding: 10px;
  border: 1px solid var(--text-color);
  border-radius: 20px;
}

.dropbtn:hover {
  transform: scale(1.2);
}

.langtext {
  margin-left: 5px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  margin-top: 5px;
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  border-radius: 4px;

}

.dropdown-content span {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  border-radius: 4px;
}

/* Change color of dropdown links on hover */
.dropdown-content span:hover {
  background-color: #ddd;
  color: #8C0AC8; /* Change text color on hover */
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {display: block;}
